import React, { useState, Fragment, useRef, useEffect } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import queryString from 'query-string';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
// import ReCAPTCHA from 'react-google-recaptcha';
import Turnstile from 'react-turnstile';
import InputMask from 'react-input-mask';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import {
  Root,
  N,
  Label,
  P,
  C,
  PS,
  Input,
  SubmitButton,
  SendAnotherOTPButton,
  YesNoButton,
  DIV,
  Center,
  Masked,
  RadioGroup,
  Check,
  GlobalStyle,
  TextArea,
} from './Styles';
import stringsToLocalize from './localisation';

import Loading from './components/Loading';
import ErrorHandler from './components/ErrorHandler';
import Success from './components/Success';
import Fail from './components/Fail';
import Intro from './components/Intro';
import HeaderLogo from './components/HeaderLogo';

import { validatePhone, validatePGN } from './utils/funcs';
import NA from './components/NA';

function App() {
  const baseURL = config.baseURL;
  const strings = new LocalizedStrings(stringsToLocalize);

  const [na, setNa] = useState(true);
  const [lang, setLang] = useStateWithCallbackLazy('Ar');
  const [GRM, setGRM] = useStateWithCallbackLazy(null);
  const [Other, setOther] = useStateWithCallbackLazy(null);
  const [hasOther, setHasOther] = useStateWithCallbackLazy(false);
  const [guid, setGUID] = useStateWithCallbackLazy(null);
  const [loading, setLoading] = useState(false);
  const [stop, setStop] = useState(null);
  const [maskedPhone, setMaskedPhone] = useStateWithCallbackLazy(null);
  const [showSendAnother, setShowSendAnother] = useState(false);
  const [validOTP, setValidOTP] = useState(false);
  const [errorHandler, setErrorHandler] = useStateWithCallbackLazy(null);

  const [validToken, setValidToken] = useState(false);

  const [error, setError] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  const [cont, setCont] = useState(null);

  const [Phone, setPhone] = useStateWithCallbackLazy(null);
  const [step, setStep] = useState(0);
  const [GRMs, setGRMs] = useState([]);
  const [isMounted, setIsMounted] = React.useState(false);

  const pgnRef = useRef(null);
  const phoneRef = useRef(null);
  const otpRef = useRef(null);

  strings.setLanguage(lang);
  useEffect(() => {
    if (GRM) {
      let _hasOther = false;
      GRM.split('|').forEach((e) => {
        GRMs.forEach((g) => {
          if (g.value === e) {
            if (g.HasOther) {
              _hasOther = true;
            }
          }
        });
      });
      setHasOther(_hasOther);
    } else {
      setHasOther(false);
    }
  }, [GRM, setHasOther, GRMs]);
  useEffect(() => {
    let l = queryString.parse(window.location.search)['lang'];
    l = l ? l : config.lang;
    setLang(l);
  }, [setLang]);
  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      switch (e.target) {
        case pgnRef.current:
          handleSubmitPGN();
          break;
        case otpRef.current:
          handleSubmitOTP();
          break;
        default:
          break;
      }
    }
  };
  const handleSubmitNext = async () => {
    setStep(1);
  };
  const handleSubmitPGN = async () => {
    setErrorHandler(null);
    if (!validToken) {
      setErrorHandler('CAPTCHA');
      return;
    }
    // if (!recaptchaRef.current.getValue()) {
    //   setErrorHandler('CAPTCHA');
    //   return;
    // }

    const pgnx = validatePGN(pgnRef.current.value);
    if (pgnx === false) {
      setErrorHandler('WP');
    } else {
      setLoading(true);
      let result = {};
      try {
        result = await axios.post(baseURL + '/newx', {
          pgn: pgnx,
        });
      } catch (e) {
        setLoading(false);
        setErrorHandler('error');
        setError(true);
        return;
      }

      if (result.data.msg) {
        setLoading(false);
        setErrorHandler(result.data.msg);
        if (cont) {
          pgnRef.current.blur();
        }
      }

      let grm = result.data;
      if (!grm.maskedPhone) {
        setLoading(false);
        setStep(4);
      }
      if (grm.GUID) {
        setShowLogo(false);
        setMaskedPhone(grm.maskedPhone, () => {
          setGUID(grm.GUID);
          axios
            .post(baseURL + '/grms', { lang: lang })
            .then((res) => {
              setGRMs(res.data);
              setStep(2);
              setLoading(false);
            })
            .catch((e) => {
              setShowLogo(true);
              setErrorHandler('error');
              setError(true);
              setLoading(false);
            });
        });
      }
    }
  };
  const handleSubmitGRM = async () => {
    let _grm = GRM;
    console.log(_grm);
    if (!GRM) {
      // setErrorHandler('NG');
      // return;
    }
    setLoading(true);
    console.log(Other);
    try {
      console.log(_grm);
      await axios.post(baseURL + '/submitgrm', {
        GRMGUID: guid,
        GRMs: _grm,
        Other,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
    setShowLogo(true);
    setStep(3);
  };
  const handleSubmitPhone = async (phone = null) => {
    let p = typeof phone === 'object' || !phone ? null : phone;
    if (p === null) {
      if (phoneRef.current.value) {
        p = validatePhone(phoneRef.current.value);
      } else if (Phone) {
        p = validatePhone(Phone);
      } else {
        setErrorHandler('error');
      }
      if (p === false) {
        setErrorHandler('IP');
        return;
      }
    }

    setLoading(true);
    setShowSendAnother(false);
    let result = {};
    try {
      result = await axios.post(baseURL + '/otp', {
        GRMGUID: guid,
        phone: p,
        otpLen: config.otp,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }

    setLoading(false);
    let msg = 'OR';
    if (result.data.msg) {
      msg = result.data.msg;
    }
    setErrorHandler(msg);
    setPhone(p, () => {
      setStep(5);
    });
    setTimeout(() => {
      setShowSendAnother(true);
    }, 10000);
  };
  const handleSubmitSamePhone = async (samePhone) => {
    setLoading(true);
    let result = {};
    try {
      result = await axios.post(baseURL + '/samephone', {
        GRMGUID: guid,
        samephone: samePhone,
      });
      if (result.data.PhoneNumber) {
        handleSubmitPhone(result.data.PhoneNumber);
      }
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
  };
  const handleSubmitOTP = async () => {
    setLoading(true);
    let result = {};
    try {
      result = await axios.post(baseURL + '/verifyotp', {
        GRMGUID: guid,
        otp: otpRef.current.value,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
    if (result.data.Valid) {
      setValidOTP(true);
    } else {
      setErrorHandler('WO');
    }
  };

  const otpDiv = (
    <DIV>
      <P>{strings.enterOTP}</P>

      <Input
        maxLength={config.otp}
        type='number'
        inputMode='numeric'
        pattern='[0-9]{6}'
        ref={otpRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />

      <SubmitButton onClick={handleSubmitOTP} style={{ marginTop: '10px' }}>
        {strings.submit}
      </SubmitButton>
      {!!showSendAnother && (
        <SendAnotherOTPButton
          onClick={() => {
            handleSubmitPhone(Phone);
          }}
          style={{ marginTop: '10px' }}
        >
          {strings.submitAnotherOTP}
        </SendAnotherOTPButton>
      )}
    </DIV>
  );
  const pgnDiv = (
    <DIV>
      <P>{strings.enterPGN}</P>
      <InputMask mask={'*** - 99 C/- 9999999'} maskChar={'_'}>
        {(inputProps) => (
          <Input
            {...inputProps}
            ref={pgnRef}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        )}
      </InputMask>
      {validToken && (
        <SubmitButton onClick={handleSubmitPGN} style={{ marginTop: '10px' }}>
          {strings.begin}
        </SubmitButton>
      )}
      <Turnstile
        style={{
          marginTop: '10px',
        }}
        sitekey='0x4AAAAAAABbKzjrbpOpT2ZU'
        onVerify={(token) => {
          setValidToken(true);
        }}
        onError={() => {
          // setValidToken(false);
          setValidToken(true);
          setErrorHandler('CAPTCHA');
        }}
        language={lang.toLowerCase()}
      />
      {/* <ReCAPTCHA
        style={{ marginTop: '1em' }}
        ref={recaptchaRef}
        sitekey='6LdsHwojAAAAAGBvUKu6srnDo4RDsJtg4bB0hh6C'
        hl={lang}
      /> */}
    </DIV>
  );
  const introDiv = (
    <DIV>
      <Intro dir={strings.dir} text={strings.intro} />
      <SubmitButton onClick={handleSubmitNext} style={{ marginTop: '10px' }}>
        {strings.ok}
      </SubmitButton>
    </DIV>
  );

  const stillHasPhoneButtons = (
    <Fragment>
      <P>{strings.samePhone}</P>
      <Masked dir='LTR'>{maskedPhone}</Masked>
      <Center>
        <YesNoButton
          style={
            strings.dir.toLowerCase() === 'ltr'
              ? { marginRight: '0.2em' }
              : { marginLeft: '0.2em' }
          }
          onClick={() => {
            handleSubmitSamePhone(1);
            setStep(5);
          }}
        >
          {strings.yes}
        </YesNoButton>
        <YesNoButton
          onClick={() => {
            handleSubmitSamePhone(0);
            setStep(4);
          }}
        >
          {strings.no}
        </YesNoButton>
      </Center>
    </Fragment>
  );
  const grmsDiv = (
    <DIV>
      <P>{strings.heardAbout}</P>
      <RadioGroup dir={strings.dir}>
        {GRMs.map((g, i) => {
          return (
            <Label key={`grm-key-${i}`} dir={strings.dir} htmlFor={`grm-${i}`}>
              <Check
                type='checkbox'
                id={`grm-${i}`}
                value={g.value}
                dir={strings.dir}
                name='grms'
                onChange={(e) => {
                  let grms = GRM ? GRM.split('|') : [];
                  if (e.target.checked) {
                    grms.push(e.target.value);
                  } else {
                    grms.forEach((el, i) => {
                      if (el === e.target.value) grms.splice(i, 1);
                    });
                  }
                  grms = grms.join('|');
                  setGRM(grms);
                }}
              />
              {g.label}
            </Label>
          );
        })}
      </RadioGroup>
      {hasOther && (
        <TextArea
          dir={strings.dir}
          onKeyUp={(e) => {
            setOther(e.target.value);
          }}
        />
      )}
      <PS>{strings.submitToLog}</PS>
      <N dir={strings.dir}>{strings.note}</N>
      <SubmitButton onClick={handleSubmitGRM} style={{ marginTop: '10px' }}>
        {strings.submit}
      </SubmitButton>
    </DIV>
  );
  const stillHasPhoneDiv = <div>{stillHasPhoneButtons}</div>;
  let Content = null;
  switch (step) {
    case 0:
      Content = introDiv;
      break;
    case 1:
      Content = pgnDiv;
      break;
    case 2:
      Content = grmsDiv;
      break;
    case 3:
      Content = stillHasPhoneDiv;
      break;
    case 4:
      Content = <Fail html={strings.fail}></Fail>;
      break;
    case 5:
      Content = otpDiv;
      break;
    default:
      break;
  }
  if (na) Content = <NA dir={strings.dir} msg={strings.na} />;
  return (
    <Fragment>
      {!!isMounted && (
        <Root dir={strings.dir}>
          {/* {showLogo && <Logo src={logo} />} */}
          {showLogo && <HeaderLogo width={window.innerWidth} />}
          {!!validOTP && <Success text={strings.thankYou} />}
          {!validOTP && (
            <>
              {!!loading && <Loading />}
              {!loading && !stop && !error && <C>{Content}</C>}
              {!loading && !!errorHandler && !error && (
                <ErrorHandler
                  lang={strings.lang}
                  setErrorHandler={setErrorHandler}
                  setStop={setStop}
                  msg={errorHandler}
                  setCont={setCont}
                />
              )}
              {!!error && (
                <>
                  <ErrorHandler
                    lang={strings.lang}
                    setErrorHandler={setErrorHandler}
                    setStop={setStop}
                    msg={errorHandler}
                  />
                  <SubmitButton
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {strings.tryAgain}
                  </SubmitButton>
                </>
              )}
            </>
          )}
        </Root>
      )}
      <GlobalStyle />
    </Fragment>
  );
}

export default App;
