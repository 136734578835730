import styled, { createGlobalStyle } from 'styled-components';
import c from './utils/constants';

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    -ms-overflow-style: none;
    
  }
  
  html {
    height: 100%;
  }
::-webkit-scrollbar {
    display: none;
}

  body {

    height: 100%;
    padding: 32px;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    
  }
  div#root {
    height: 100%;
  }
`;
export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled.img`
  width: 10em;
  margin-top: 1em;
  margin-bottom: 1em;
`;
export const P = styled.p`
  font-size: 2em;
  color: ${c.blue};
  text-align: center;
`;
export const Input = styled.input`
  width: 14em;
  height: 2em;
  border: 1px solid ${c.lightGrey};
  border-radius: 3px;
  font-size: larger;
  padding: 1em;
  :focus {
    outline: none;
    box-shadow: 0px 0px 2px ${c.primary};
  }
  font-weight: 100;
  color: ${c.text};
  direction: LTR;
`;
export const Button = styled.button`
  font-size: 1.5em;
  width: 5em;
  border-radius: 3px;
  border: 1px solid ${c.lightGrey};
  box-shadow: 0px 0px 2px ${c.lightGrey};
  :hover {
    box-shadow: 0px 0px 4px ${c.primary};
  }
  :focus {
    outline: none;
    box-shadow: 0px 0px 8px ${c.primary};
  }
`;
export const SendAnotherOTPButton = styled(Button)`
  font-weight: 100;
  border: none;
  background-color: transparent;
  font-size: 1em;
  color: ${c.primary};
  box-shadow: none;
  width: auto;
  :focus {
    outline: none;
    box-shadow: none;
  }
  :hover {
    box-shadow: none;
  }
`;
export const SubmitButton = styled(Button)`
  background-color: ${c.white};
  font-weight: 100;
  color: ${c.text};
`;
export const YesNoButton = styled(Button)`
  height: 5em;
  background-color: ${c.primary};
  color: ${c.white};
  border-radius: 10px;
`;
export const DIV = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;
export const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
export const Masked = styled.span`
  color: ${c.danger};
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  font-weight: bold;
`;
export const Check = styled.input`
  ${({ dir }) => {
    return dir.toLowerCase() === 'rtl'
      ? `margin-left: 0.5em`
      : `margin-right: 0.5em`;
  }}
`;
export const Label = styled.label`
  color: ${c.text};
  font-weight: 100;
  font-size: 1.5em;
`;
export const RadioGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0.5em;
`;
export const C = styled.div`
  width: 100vw;
`;
export const PS = styled.p`
  font-weight: 100;
  font-size: small;
  font-style: italic;
  color: ${c.primary};
`;
export const N = styled.p`
  font-size: 0.8em;
  color: ${c.danger};
  text-align: justify;
`;
export const TextArea = styled.textarea`
  width: 14em;
  height: 4em;
  border: 1px solid ${c.lightGrey};
  border-radius: 3px;
  font-size: larger;
  padding: 1em;
  :focus {
    outline: none;
    box-shadow: 0px 0px 2px ${c.primary};
  }
  font-weight: 100;
  color: ${c.text};
  ${({ dir }) => {
    return dir.toLowerCase() === 'rtl' ? `direction: RTL;` : `direction: LTR;`;
  }}
`;
