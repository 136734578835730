export default {
  success: '#00b398',
  primary: '#0072bc',
  danger: '#eb4034',
  lightGrey: '#bab8b8',
  white: '#f7f7f7',
  text: 'rgba(28, 28, 28, 0.8)',
  textDark:'rgba(71, 71, 71, 0.7)',
  blue: 'rgb(23, 111, 166)',
  orange: '#f5a700',
  lightOrange: '#ffc54a'
};
