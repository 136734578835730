import React from 'react';
import { Root } from './styles';
import LoadingImg from './LoadingImg';

const Loading = (props) => {
  return (
    <Root>
      <LoadingImg />
    </Root>
  );
};
export default Loading;
