import styled  from 'styled-components';

export const DIV = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
width: 100%;
margin-bottom: 3em;
margin-top: 2em;
width: 100vw;
`;
export const UNHCRLogo = styled.img`
width: 9em;
height: 10em;
`;
export const WFPLogo = styled.img`
width: 7em;
`;